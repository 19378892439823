<template>
  <div>
    <v-img
      src="../../../src/assets/fondo-gris-abstracto_29865-2379.webp"
      height="600"
      width="100%"
    >
      <v-row>
        <v-col cols="12" md="6" class="pt-15 mt-10">
          <p
            class="text-left mx-15"
            style="
              color: #179db0;
              font-family: Didact Gothic, sans-serif;
              font-size: 85px;
            "
          >
            Bienvenido a <label>Sipymex</label>
          </p>
          <p
            class="text-left mx-15"
            style="
              color: #213b7c;
              font-family: Quicksand, Helvetica, sans-serif;
              font-size: 30px;
            "
          >
            Enfocados en ofrecer soluciones integrales que contribuyen a una
            mayor eficiencia de gestión en su negocio.
          </p>
        </v-col>

        <v-col cols="12" md="6">
          <v-carousel
            class="pr-15 mt-10 mb-15"
            hide-delimiter-background
            hide-delimiters
            cycle
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-img>
    <v-container>
      <v-row class="mt-10 mb-10">
        <v-col cols="12" md="12">
          <div class="text-center">
            <p class="text-h4" style="color: #213b7c">Nuestros</p>
            <p class="text-h2 font-weight-bold" style="color: #179db0">
              Servicios
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-10 mb-10">
        <v-col cols="12" md="1"></v-col>
        <v-col cols="12" md="10">
          <v-row class="">
            <v-col cols="12" md="3" v-for="(service, i) in services" :key="i">
              <v-card class="mx-auto" max-width="300" height="100%">
                <div style="text-align: center">
                  <br />
                  <v-btn class="ma-2" outlined x-large fab color="primary">
                    <v-icon color="primary">{{ service.icon }}</v-icon>
                  </v-btn>
                  <div class="text-h6 mt-4" color="primary">
                    {{ service.name }}
                  </div>
                </div>
                <v-card-subtitle> {{ service.abstrac }} </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="1"></v-col>
      </v-row>
      <v-img
        src="../../../src/assets/home_service.png"
        height="360"
        width="100%"
      >
        <br />
        <br />
        <br />
        <p class="text-h1 text-center font-weight-bold" style="color: white">
          ¿Por qué elegirnos?
        </p>
        <p
          class="text-justify text-h4 mx-15"
          style="color: white; text-shadow: 2px 2px 2px #8f9394"
        >
          Te apoyamos en el proceso de transición, te brindamos capacitaciones
          personalizadas y acción oportuna ante tus requerimientos para que tu
          negocio siga en marcha.
        </p>
      </v-img>

      <v-row>
        <v-col cols="12" md="12">
          <v-card
            align="center"
            color="white"
            class="d-md-inline-flex pb-10 pt-5"
            elevation="0"
            outlined
          >
            <v-row class="mt-10">
              <v-col cols="12" md="5">
                <v-img
                  src="../../../src/assets/home.png"
                  class="text-right"
                  aspect-ratio="1.7"
                  contain
                  max-width="100%"
                  max-height="100%"
                ></v-img>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-xl-h2 font-weight-black text-left">
                  <p
                    class="text-h2 font-weight-bold pt-15"
                    style="color: #213b7c"
                  >
                    Boleta electrónica
                  </p>

                  <p class="text-justify text-left text--secondary text-h6">
                    <strong>¿Su negocio está preparado? </strong> <br />
                    Porque en Sipymex sabemos que su tiempo es lo más valioso,
                    estamos enfocados en ofrecer soluciones integrales que
                    contribuyen a una mayor eficiencia de gestión en su negocio,
                    con un trato personalizado y rápida respuesta a sus
                    requerimientos.
                  </p>
                  <v-btn color="primary" x-large elevation="2">SABER MÁS</v-btn>
                </div>
              </v-col>
              <v-col cols="12" md="1"></v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <v-card
            align="center"
            color="#213b7c"
            dark
            class="d-md-inline-flex"
            outlined
            width="100%"
            height="100%"
          >
            <v-row class="mt-10">
              <v-col cols="12" md="1"></v-col>
              <v-col cols="12" md="6">
                <div class="text-xl-h2 font-weight-black text-left">
                  <p
                    class="text-h2 font-weight-bold pt-15"
                    style="color: white"
                  >
                    ¿Tienes un computador?
                  </p>
                  <p class="text-justify text-left text-h6 pt-5">
                    No requieres nada más, con nuestro sistema puedes usar los
                    equipos que ya tienes. Solo necesitas un computador de punto
                    fijo en local con conexión a internet y una impresora
                    térmica de 80mm.
                  </p>
                  <v-btn color="primary" x-large elevation="2"
                    >CONSULTA FACTIBILIDAD</v-btn
                  >
                </div>
              </v-col>
              <v-col cols="12" md="5">
                <v-img
                  src="../../../src/assets/home.png"
                  class="text-right"
                  aspect-ratio="1.7"
                  contain
                  max-width="100%"
                  max-height="100%"
                ></v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <v-card
            align="center"
            color="white"
            class="d-md-inline-flex pt-5 pb-10"
            outlined
            width="100%"
            height="100%"
          >
            <v-row class="mt-10">
              <v-col cols="12" md="5">
                <v-img
                  src="../../../src/assets/home.png"
                  class="text-right"
                  aspect-ratio="1.7"
                  contain
                  max-width="100%"
                  max-height="100%"
                ></v-img>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-xl-h2 font-weight-black text-left">
                  <p
                    class="text-h2 font-weight-bold pt-15"
                    style="color: #213b7c"
                  >
                    Primera calidad en equipos
                  </p>
                  <p class="text-justify text-left text-h6">
                    Porque el movimiento de tu negocio está en juego, en Sipymex
                    ofrecemos equipos de primera calidad de la marca líder en el
                    mercado de puntos de ventas Sunmi, asegurando su calidad y
                    fiabilidad de operación.
                  </p>
                  <v-btn color="primary" x-large elevation="2"
                    >VER EQUIPOS</v-btn
                  >
                </div>
              </v-col>
              <v-col cols="12" md="1"></v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <v-card
            align="center"
            color="#EEEEEE"
            dark
            class="d-md-inline-flex"
            outlined
            width="100%"
            height="100%"
          >
            <v-row class="mt-10">
              <v-col cols="12" md="1"></v-col>
              <v-col cols="12" md="6">
                <div class="text-xl-h2 font-weight-black text-left">
                  <p
                    class="text-h2 font-weight-bold pt-15"
                    style="color: #213b7c"
                  >
                    ¿Necesitas una página web?
                  </p>
                  <p class="text-justify text-left text--secondary text-h6">
                    Nuestros planes de hosting incluyen almacenamiento<br />
                    ilimitado y registro de dominio web gratuito
                  </p>
                  <v-btn color="primary" x-large elevation="2"
                    >PLANES A TU MEDIDA</v-btn
                  >
                </div>
              </v-col>
              <v-col cols="12" md="5">
                <v-img
                  src="../../../src/assets/web.png"
                  class="text-right"
                  aspect-ratio="1.7"
                  contain
                  max-width="100%"
                  max-height="100%"
                ></v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="12">
          <v-card
            align="center"
            color="white"
            class="d-md-inline-flex"
            outlined
            width="100%"
            height="100%"
          >
            <v-row class="mt-10 mb-10">
              <v-col cols="12" md="5">
                <v-img
                  src="../../../src/assets/firma.png"
                  class="text-right"
                  aspect-ratio="1.7"
                  contain
                  max-width="100%"
                  max-height="100%"
                ></v-img>
              </v-col>

              <v-col cols="12" md="6">
                <div class="text-xl-h2 font-weight-black text-left">
                  <p
                    class="text-h2 font-weight-bold pt-15"
                    style="color: #213b7c"
                  >
                    ¿Firma electrónica?
                  </p>
                  <p class="text-justify text-left text--secondary text-h6">
                    Herramienta indispensable para la emisión de documentos
                    electrónicos y firma de documentos varios. Si no cuenta con
                    certificado digital o este ha vencido, compra aquí desde
                    $19.000 Iva inc.
                  </p>
                  <v-btn color="primary" class="" x-large elevation="2"
                    >COMPRA AQUÍ TU FIRMA</v-btn
                  >
                </div>
              </v-col>
              <v-col cols="12" md="1"></v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      icons: ["mdi-instagram", "mdi-facebook"],
      items: [
        {
          src: "require(/../../src/assets/pexels-thisisengineering-3861958.jpg)",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
        },
      ],
      padless: true,
      show: false,
      services: [
        {
          name: "Boleta electrónica",
          abstrac:
            "Pensado para negocios que requieran de una forma sencilla, cómoda y eficaz para emitir boletas electrónica.",
          description: "Descripcion",
          icon: "mdi-ticket-confirmation",
          color: "#0c71af",
        },
        {
          name: "Facturación electrónicas",
          abstrac:
            "Lleva tu negocio al siguiente nivel con nuestros planes de facturación electrónica",
          description:
            "Lleva tu negocio al siguiente nivel con nuestros planes de facturación electrónica",
          icon: "mdi-file-document",
        },
        {
          name: "Firma electrónica",
          abstrac:
            "Adquiere aquí tu firma electrónica indispensable para la emisión de documentos tributarios electrónicos.",
          description: "Descripcion",
          icon: "mdi-draw-pen",
        },
        {
          name: "Hosting",
          abstrac:
            "Planes de hosting asequibles para pequeñas y medianas empresas que deseen incursionar en la web.",
          description: "Descripcion",
          icon: "mdi-file-cloud",
        },
      ],
      colors: ["primary", "warning", "success"],
      slides: ["Boleta electronica ¿Su negocio está preparado?"],
      images: [
        { src: "../../src/assets/01.jpg" },
        { src: "../../src/assets/01.jpg" },
        { src: "../../src/assets/01.jpg" },
        { src: "../../src/assets/01.jpg" },
      ],
      plans: [
        {
          text: "Emisión de folios ilimitada",
          icon: "mdi-check-decagram",
          color: "primary",
        },
        {
          text: "Múltiples usuarios.",
          icon: "mdi-check-decagram",
          color: "primary",
        },
        {
          text: "Cuenta exclusiva para el contador.",
          icon: "mdi-check-decagram",
          color: "primary",
        },
        {
          text: "Volumen de venta de $10.000.000 mensuales* ",
          icon: "mdi-check-decagram",
          color: "primary",
        },
        {
          text: "Emisión sin conexión a internet* (debe conectarse al menos una vez al día para sincronizar documentos con el Sii)",
          icon: "mdi-check-decagram",
          color: "primary",
        },
      ],
    };
  },
};
</script>